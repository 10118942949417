.contact-page {
  margin-top: 50px;
  /* transition: 0.3s all ease-out;
  box-shadow: inset 1px 1px 1px rgba(255,255,255,0.4), 1px 1px 1px rgba(0,0,0,0.3), -1px -1px 1px rgba(0,0,0,0.15), 0 0px 4px rgba(0, 0, 0, 0), 0 0px 10px rgba(0, 0, 0, 0.20); */
}

.twist-card {
  transform: rotate(1deg);
}

.postcard {
  background: #f5f2e9 url(../../assets/airmail.jpg) 0 0 repeat;
  box-shadow: inset 1px 1px 1px rgba(255,255,255,0.4), 1px 1px 1px rgba(0,0,0,0.3), -1px -1px 1px rgba(0,0,0,0.15), 0 0px 4px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.20);   
  padding: 5px;
}

.postcard-content {
  border-radius: 3px;
  border-style: dotted;
  border-width: 1px;
  border-color: rgba(100,100,100,0.4);
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,0), 0 0 1px rgba(0,0,0,0), 0 1px 1px rgba(0, 0, 0, 0), 0 1px 4px rgba(0, 0, 0, 0.5);
}

.post-header-area {
  /* background-color: yellow; */
  padding: 10px;
  height: 150px;
}

.stamp-area {  
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* background-color: red; */
}

.stamp-impression {
  transform: translate(-10px, -45px);
  width: 100px;
}

.stamp {
  transform: rotate(9deg);
}

.header-text{
  font-family: 'Satisfy', cursive;
  font-size: 40px;
  color: rgba(7, 82, 167, 0.699);
}