.content-area {
  /* width: 100%; */
  height: 100%;
  padding-bottom: 100px;
}

.hello-text {
  font-size: 40px;
}

.hello-language{
  vertical-align: 20px;
  font-size: 9px;
  color: gray;
}