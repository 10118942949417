.content-area {
  /* width: 100%; */
  height: 100%;
  padding-bottom: 100px;
}

.hello-text {
  font-size: 40px;
}

.hello-language{
  vertical-align: 20px;
  font-size: 9px;
  color: gray;
}
.contact-page {
  margin-top: 50px;
  /* transition: 0.3s all ease-out;
  box-shadow: inset 1px 1px 1px rgba(255,255,255,0.4), 1px 1px 1px rgba(0,0,0,0.3), -1px -1px 1px rgba(0,0,0,0.15), 0 0px 4px rgba(0, 0, 0, 0), 0 0px 10px rgba(0, 0, 0, 0.20); */
}

.twist-card {
  -webkit-transform: rotate(1deg);
          transform: rotate(1deg);
}

.postcard {
  background: #f5f2e9 url(/static/media/airmail.7752bfda.jpg) 0 0 repeat;
  box-shadow: inset 1px 1px 1px rgba(255,255,255,0.4), 1px 1px 1px rgba(0,0,0,0.3), -1px -1px 1px rgba(0,0,0,0.15), 0 0px 4px rgba(0, 0, 0, 0.2), 0 0px 10px rgba(0, 0, 0, 0.20);   
  padding: 5px;
}

.postcard-content {
  border-radius: 3px;
  border-style: dotted;
  border-width: 1px;
  border-color: rgba(100,100,100,0.4);
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 5px rgba(0,0,0,0), 0 0 1px rgba(0,0,0,0), 0 1px 1px rgba(0, 0, 0, 0), 0 1px 4px rgba(0, 0, 0, 0.5);
}

.post-header-area {
  /* background-color: yellow; */
  padding: 10px;
  height: 150px;
}

.stamp-area {  
  display: -webkit-flex;  
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  /* background-color: red; */
}

.stamp-impression {
  -webkit-transform: translate(-10px, -45px);
          transform: translate(-10px, -45px);
  width: 100px;
}

.stamp {
  -webkit-transform: rotate(9deg);
          transform: rotate(9deg);
}

.header-text{
  font-family: 'Satisfy', cursive;
  font-size: 40px;
  color: rgba(7, 82, 167, 0.699);
}
.app {
  overflow: scroll;
  height: 100%;
  padding-top: 55px;
  padding-bottom: 50px;
  /* font-family: 'Concert One', cursive; */
  /* font-family: 'Geo', sans-serif; */
  /* font-family: 'Ruluko', sans-serif; */
  /* font-family: 'Chathura', sans-serif; */
  font-family: 'Cairo', sans-serif;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loadComponent-appear {
  opacity: 0;
}
.loadComponent-appear.loadComponent-appear-active {
  opacity: 1;
  transition: all 0.6s linear;
}
.loadComponent-enter {
  opacity: 0;
}
.loadComponent-enter.loadComponent-enter-active {
  opacity: 1;
  transition: all 0.6s linear 0.4s;
}
.loadComponent-leave {
  opacity: 1.0;
  position:absolute
}
.loadComponent-leave.loadComponent-leave-active {
  opacity: 0;
  transition: all 0.6s linear;
  position:absolute;
}

.footerbackgrund {
  padding: 20px;
  background-image: url(/static/media/usa.3a2b01f6.png);
  background-repeat: no-repeat;
  background-position-x: 47%;
  background-position-y: bottom;
  background-size: contain;
}
