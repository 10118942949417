.app {
  overflow: scroll;
  height: 100%;
  padding-top: 55px;
  padding-bottom: 50px;
  /* font-family: 'Concert One', cursive; */
  /* font-family: 'Geo', sans-serif; */
  /* font-family: 'Ruluko', sans-serif; */
  /* font-family: 'Chathura', sans-serif; */
  font-family: 'Cairo', sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loadComponent-appear {
  opacity: 0;
}
.loadComponent-appear.loadComponent-appear-active {
  opacity: 1;
  transition: all 0.6s linear;
}
.loadComponent-enter {
  opacity: 0;
}
.loadComponent-enter.loadComponent-enter-active {
  opacity: 1;
  transition: all 0.6s linear 0.4s;
}
.loadComponent-leave {
  opacity: 1.0;
  position:absolute
}
.loadComponent-leave.loadComponent-leave-active {
  opacity: 0;
  transition: all 0.6s linear;
  position:absolute;
}

.footerbackgrund {
  padding: 20px;
  background-image: url('./assets/usa.png');
  background-repeat: no-repeat;
  background-position-x: 47%;
  background-position-y: bottom;
  background-size: contain;
}